var page = 1,
    moving = false;
var animationIteration = "animationiteration webkitAnimationIteration mozAnimationIteration oAnimationIteration oanimationiteration",
	transitionEnd      = "transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd";


function access_website()
{
  if ( moving == false ) {
    moving = true;
    $(".loading-arrow").addClass("active");

      $.ajax({
        type: "POST",
        url: path+"/access",
        data: "&access_key="+encodeURIComponent($("#access_key").val()),
        headers: {
              'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function(response){
          if (response.success === true)
          {
            setTimeout(function() {
              window.location.href = response.access_url;
            }, 1500);
          }
          else
          {
            setTimeout(function() {
              $(".loading-arrow").one(animationIteration, function() {
                $(".loading-arrow").removeClass("active");
                $(".loading-arrow").one(transitionEnd, function() {
                  page++;
                  moving = false;

                });
              });
            }, 1500);
          }
        }
      });
  }
}



var windowsize = $(window).width();

$( document ).ready(function() {
  if (windowsize > 992) {
      TweenLite.to($(".right-content"), 0.5, {y:"0%", opacity:1});
      TweenLite.to($(".right-content"), 0.5, {x:"0%", delay:1});
      TweenLite.from($(".left-content"), 0.5, {x:"50%", opacity:0, delay:1});
  } else {
      TweenLite.to($(".right-content"), 0.5, {y:"0%", opacity:1, delay:0.3});
      TweenLite.to($(".left-content"), 0.5, {y:"0%",  opacity:1, delay:0.3});
  }
});

TweenLite.to($(".right-content form .animation-label"), 0.5, {opacity:0, delay:1});
TweenLite.from($(".right-content form .form-content"), 0.5, {opacity:0, delay:1});

$('.input__field').keyup(function(){
    if( $(this).val() != ""){
        $(this).addClass("empty");
    }else{
        $(this).removeClass("empty");
    }
});

$(".loading-arrow").on("click", function() {
  access_website();
});


$('body').on('keydown', function(e) {
    if (e.which == 13) {
        e.preventDefault();
        access_website();
    }
});
